<template>
    <view-layout>
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                </template>
            </page-header>
        </template>
        <template #content>
            <v-card outlined>
                <accountant-invitation-table />
            </v-card>
        </template>
    </view-layout>
</template>

<script>
// --- Components ---
import AccountantInvitationTable from '@/components/DataTables/AccountantInvitationTable.vue';
import ViewLayout from '@/components/ViewLayout.vue';
import PageHeader from '@/components/PageHeader.vue';

export default {
    components: {
        AccountantInvitationTable,
        ViewLayout,
        PageHeader
    }
};
</script>
