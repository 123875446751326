import axios from 'axios';
import { IGetAllAccountantUserViewModel } from 'dexxter-types';
import { generateEndpoint } from '@/services/config';
import {
    IAccountantuserViewModel,
    IAccountingOfficeViewModel,
    RegisterAccountantEmployeeData,
    UPdateAccountingOfficeData
} from '@/services/accountingoffice.types';

const BASE_URL = generateEndpoint('/api/accountingOffice');

export function getAllAccountantsRequest(): Promise<IAccountantuserViewModel> {
    return axios.get(`${BASE_URL}/accountants`).then((response) => response.data);
}

export function getAccountingOfficeOfCurrentAccountantRequest(): Promise<IAccountingOfficeViewModel> {
    return axios.get(`${BASE_URL}/current`).then((response) => response.data);
}

export function addAccountantRequest(
    registrationData: RegisterAccountantEmployeeData
): Promise<IAccountantuserViewModel> {
    return axios.post(`${BASE_URL}/accountants`, registrationData).then((response) => response.data);
}

export function deleteAccountantRequest(accountantId: number): Promise<void> {
    return axios.delete(`${BASE_URL}/accountants/${accountantId}`).then((response) => response.data);
}

export function deleteAccountantCustomerFromAccountantOfficeRequest(accountantId: number): Promise<void> {
    return axios.delete(`${BASE_URL}/accountantingusers/${accountantId}`).then((response) => response.data);
}

export function sendGetAccountantOfficeUsersRequest(): Promise<IGetAllAccountantUserViewModel[]> {
    return axios.get(`${BASE_URL}/users`).then((response) => response.data);
}

export function sendUpdateAccountingOfficeSettingsRequest(data: UPdateAccountingOfficeData): Promise<void> {
    return axios.put(`${BASE_URL}/settings`, data).then((response) => response.data);
}
