import { cloneDeep } from 'lodash';
import Vue from 'vue';
import { mapState } from 'vuex';
export const itemsPerPageOptions = [10, 20, 50];
export const standardLimit = 10;

const generalTableKey = 'GeneralTableSettings';

const defaultSettings = {
    limit: standardLimit
};

type tableConfig = Record<string, { limit: number }>;

export const tableName = Symbol('TableNameFiltering');

export const filteringMixin = Vue.extend({
    data() {
        return {
            pagination: {
                currentPage: 1,
                limit: standardLimit,
                itemsPerPageOptions
            },
            query: {
                q: null as null | string,
                sortDesc: null as null | boolean,
                sortByColumn: null as null | string
            },
            loading: false
        };
    },

    computed: {
        ...mapState(['selectedYear'])
    },

    watch: {
        'pagination.limit'(limit) {
            this.updateTableItemsPerPageInLocalStorage(limit);
        },
        selectedYear() {
            this.loading = true;
            (this as any).wrapperFunction().finally(() => {
                this.loading = false;
            });
        },
        query: {
            handler() {
                this.loading = true;
                (this as any).wrapperFunction().finally(() => {
                    this.loading = false;
                });
            },
            deep: true
        }
    },

    created() {
        this.fetchTableItemsPerPageFromLocalStorage();
    },

    methods: {
        getGeneralTableConfig(): tableConfig {
            const limits = localStorage.getItem(generalTableKey);
            return limits ? JSON.parse(limits) : {};
        },
        setGeneralTableSettings(config: tableConfig) {
            localStorage.setItem(generalTableKey, JSON.stringify(config));
        },
        fetchTableItemsPerPageFromLocalStorage() {
            const generalTableSettings = this.getGeneralTableConfig();

            if (generalTableSettings[this.getTableName()]) {
                this.pagination.limit = generalTableSettings[this.getTableName()]?.limit ?? standardLimit;
            } else {
                this.pagination.limit = standardLimit;
            }
        },
        getUpdatedTableSettingsConfig(
            generalTableSettings: tableConfig,
            { newLimit }: { newLimit: number }
        ): tableConfig {
            const cp = cloneDeep(generalTableSettings);

            if (generalTableSettings[this.getTableName()]) {
                cp[this.getTableName()] = {
                    ...cp[this.getTableName()],
                    limit: newLimit
                };
            } else {
                cp[this.getTableName()] = {
                    ...defaultSettings,
                    limit: newLimit
                };
            }

            return cp;
        },
        getTableName(): string {
            return (this as any)._data[tableName] ?? 'defaultTable';
        },
        updateTableItemsPerPageInLocalStorage(newLimit: number): void {
            const generalTableSettings = this.getGeneralTableConfig();

            const newVersion = this.getUpdatedTableSettingsConfig(generalTableSettings, {
                newLimit
            });

            this.setGeneralTableSettings(newVersion);
        },
        $_filtering_handleSortColumn(column: string) {
            this.query.sortByColumn = column;
        },
        $_filtering_handleSortDirectionDesc(sortDesc: boolean) {
            this.query.sortDesc = sortDesc;
        },
        $_handlePaginationRefresh(params: { currentPage: number; limit: number }) {
            this.pagination.currentPage = params.currentPage;
            this.pagination.limit = params.limit;
            this.loading = true;
            (this as any).wrapperFunction().finally(() => {
                this.loading = false;
            });
        }
    }
});
