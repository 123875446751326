<template>
    <div data-table="accountant-users-table">
        <v-data-table
            :headers="headers"
            :items="accountantUsers"
            item-key="uid"
            :items-per-page="10"
            :search="search"
            :loading="loading"
            multi-sort
        >
            <template #[`item.name`]="{ item }"> {{ item.firstName }} {{ item.name }} </template>

            <template #[`item.VATType`]="{ item }">
                {{ getVATType(item.vatLiable) }}
            </template>
            <template #[`item.number`]="{ item }"> BE{{ item.number }} </template>
            <template #[`item.email`]="{ item }">
                {{ item.email }}
            </template>
            <template #[`item.actions`]="{ item }">
                <v-menu bottom left>
                    <template #activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-card>
                        <v-list dense>
                            <v-list-item @click="impersonate(item.id)">
                                <v-list-item-title>{{ $t('users.impersonate') }}</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="$can('DELETE', 'manageAccountantCustomers')"
                                @click.stop="
                                    {
                                        dialog = true;
                                        toDeleteUid = item.id;
                                    }
                                "
                            >
                                <v-list-item-title>{{ $t('general.delete') }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>
            </template>
        </v-data-table>

        <confirmation-dialog
            v-if="dialog"
            v-model="dialog"
            :title="$t('general.delete')"
            :message="$t('general.confirm_delete_message')"
            :loading="loading"
            :negative-button="$t('general.cancel')"
            :positive-button="$t('general.delete')"
            @click-positive="removeAccountantUser"
            @click-negative="dialog = false"
            @click-outside="dialog = false"
        />
    </div>
</template>

<script>
// --- State ---
import { mapActions } from 'vuex';
// --- Components ---
import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';
// --- Helpers ---
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import {
    deleteAccountantCustomerFromAccountantOfficeRequest,
    sendGetAccountantOfficeUsersRequest
} from '@/services/accountingoffice';

export default {
    components: {
        ConfirmationDialog
    },

    data() {
        return {
            search: '',
            headers: [
                {
                    text: this.$t('general.name'),
                    align: 'start',
                    value: 'name'
                },
                { text: this.$t('general.number'), value: 'number' },
                { text: this.$t('general.type'), value: 'VATType' },
                { text: this.$t('general.email'), value: 'email' },
                { text: this.$t('general.phone'), value: 'phone' },
                {
                    text: this.$t('general.actions'),
                    value: 'actions',
                    sortable: false
                }
            ],
            dialog: false,
            loading: false,
            toDeleteUid: '',
            accountantUsers: []
        };
    },

    created() {
        this.fetchAccountantUsers();
    },

    methods: {
        ...mapActions('auth', ['impersonateUser']),
        getVATType(VATType) {
            return this.$t(`general.${VATType}`);
        },
        async fetchAccountantUsers() {
            this.loading = true;
            this.accountantUsers = await sendGetAccountantOfficeUsersRequest();
            this.loading = false;
        },
        async impersonate(userId) {
            this.loading = true;
            try {
                await this.impersonateUser(userId);
                window.location.href = '/dashboard';
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        },

        async removeAccountantUser() {
            this.loading = true;
            try {
                await deleteAccountantCustomerFromAccountantOfficeRequest(this.toDeleteUid);
                this.fetchAccountantUsers();
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
                this.dialog = false;
            }
        }
    }
};
</script>
