import { AccountantRegisterUserData } from 'dexxter-typesq';
import { DeepNullable } from '@/helpers/types';

export function createAccountantRegistersUserData(): DeepNullable<AccountantRegisterUserData> {
    return {
        completeUserRegistrationData: {
            address: {
                busNumber: null,
                city: null,
                number: null,
                street: null,
                zipcode: null
            },
            birthDate: null,
            company: {
                IBAN: null,
                name: null,
                number: null
            },
            companyStartDate: null,
            dexxterStartDate: null,
            professionalactivityId: null,
            role: null,
            schoolId: null,
            socialsecretariatId: null,
            subscriptionType: null,
            vatLiable: null,
            promocode: null
        },
        userRegistrationData: {
            email: null,
            firstName: null,
            language: 'nl',
            name: null,
            phone: null
        }
    };
}
