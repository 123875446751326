
// --- Components ---
import ViewLayout from '@/components/ViewLayout.vue';
import PageHeader from '@/components/PageHeader.vue';
import CreateClientForm from './components/CreateClient.form.vue';
import TableCard from '@/components/TableCard.vue';

import { createAccountantRegistersUserData } from './models/accountantRegistersUser';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { sendAccountantCreateUserRequest } from '@/services/auth';
import { DeepNullable, MapStateToComputed } from '@/helpers/types';
import {
    AccountantRegisterUserData,
    IProfessionalactivityViewModel,
    ISchoolViewModel,
    ISocialsecretariatViewModel
} from 'dexxter-types';
import { capitalize } from 'lodash';
import { sendGetAllProfessionalactivitiesRequest } from '@/services/professionalactivitiesService';
import { getSchools } from '@/services/schools';
import { sendGetAllSocialsecretariatsRequest } from '@/services/socialsecretariat';
import { InitializedUseraccountNotCompletedRegistrationAuthState } from '@/store/modules/auth';
import { mapState } from 'vuex';
import Vue from 'vue';

export default Vue.extend({
    components: {
        ViewLayout,
        PageHeader,
        CreateClientForm,
        TableCard
    },

    data() {
        return {
            clientData: createAccountantRegistersUserData(),
            loading: false as boolean,
            schools: [] as ISchoolViewModel[],
            socialSecretariats: null as null | ISocialsecretariatViewModel[],
            professionalactivities: null as IProfessionalactivityViewModel[] | null
        };
    },

    computed: {
        ...(mapState('auth', [
            'currentUserData'
        ]) as MapStateToComputed<InitializedUseraccountNotCompletedRegistrationAuthState>)
    },

    created() {
        this.fetchData();
    },

    methods: {
        async fetchData(): Promise<void> {
            try {
                this.loading = true;
                await Promise.all([
                    sendGetAllSocialsecretariatsRequest().then((data) => {
                        this.socialSecretariats = data
                            .map((ss) => {
                                return {
                                    ...ss,
                                    name: capitalize(ss.name)
                                };
                            })
                            .sort(this.sortByNameAsc);
                    }),
                    sendGetAllProfessionalactivitiesRequest(this.currentUserData.userinformation.language).then(
                        (result) => {
                            this.professionalactivities = result;
                        }
                    ),
                    getSchools().then((result) => {
                        this.schools = result;
                    })
                ]);
            } finally {
                this.loading = false;
            }
        },
        async createClient(data: DeepNullable<AccountantRegisterUserData>) {
            try {
                this.loading = true;

                await sendAccountantCreateUserRequest(data);

                this.$successMessage({
                    title: this.$i18n.t('general.succesfuly_created'),
                    text: this.$i18n.t('general.succesfuly_created')
                });

                this.$router.push({
                    name: 'accountantClients'
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        },
        sortByNameAsc(a: ISocialsecretariatViewModel, b: ISocialsecretariatViewModel): number {
            return a.name >= b.name ? 1 : -1;
        }
    }
});
