<template>
    <view-layout id="accountantDashboard">
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                </template>
                <template #menu>
                    <submit-button
                        data-action="add-new-accountingoffice-employee"
                        @click="openAddAccountingOfficeEmployee"
                    >
                        {{ $t('accountants.add_new_accountant') }}
                    </submit-button>
                </template>
            </page-header>
        </template>

        <template #content>
            <v-card outlined>
                <accountants-table :accountants="accountants" @delete-accountant="deleteAccountant" />
            </v-card>

            <add-accounting-office-employee
                v-if="addAccountingOfficeEmployeeOpenStatus"
                ref="registerEmployeeForm"
                v-model="addAccountingOfficeEmployeeOpenStatus"
                :loading="loading"
                @submit="registerAccountant"
            />
        </template>
    </view-layout>
</template>

<script>
// --- Components ---
import ViewLayout from '@/components/ViewLayout.vue';
import PageHeader from '@/components/PageHeader';
import AccountantsTable from '@/components/DataTables/AccountantsTable.vue';
import { addAccountantRequest, deleteAccountantRequest, getAllAccountantsRequest } from '@/services/accountingoffice';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import AddAccountingOfficeEmployee from '@/components/BottomSheets/AddAccountingOfficeEmployee.vue';
import { notify } from '@/helpers/successNotification';

export default {
    components: {
        ViewLayout,
        PageHeader,
        AccountantsTable,
        AddAccountingOfficeEmployee
    },

    data() {
        return {
            accountants: [],
            loading: false,
            addAccountingOfficeEmployeeOpenStatus: false
        };
    },

    async created() {
        try {
            this.loading = true;

            await this.loadAccountantsData();
        } catch (e) {
            apiErrorAndDisplay.call(this, e);
        } finally {
            this.loading = false;
        }
    },

    methods: {
        async loadAccountantsData() {
            this.accountants = await getAllAccountantsRequest();
        },
        openAddAccountingOfficeEmployee() {
            this.addAccountingOfficeEmployeeOpenStatus = true;
        },
        closeAddAccountingOfficeEmployee() {
            this.addAccountingOfficeEmployeeOpenStatus = false;
        },
        async deleteAccountant(accountantId) {
            try {
                this.loading = true;

                await deleteAccountantRequest(accountantId);

                notify.call(this, {
                    title: this.$t('addAccountingOfficeEmployee.deleted.title'),
                    text: this.$t('addAccountingOfficeEmployee.deleted.text')
                });

                await this.loadAccountantsData();
            } catch (err) {
                apiErrorAndDisplay.call(this, err);
            } finally {
                this.loading = false;
            }
        },
        async registerAccountant(data) {
            try {
                this.loading = true;

                await addAccountantRequest(data);

                notify.call(this, {
                    title: this.$t('addAccountingOfficeEmployee.registered.title'),
                    text: this.$t('addAccountingOfficeEmployee.registered.text')
                });

                await this.loadAccountantsData();

                this.$refs.registerEmployeeForm.resetForm();
                this.closeAddAccountingOfficeEmployee();
            } catch (err) {
                apiErrorAndDisplay.call(this, err);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>
