var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"users-table"},[_c('v-data-table',{staticClass:"pagination-table",attrs:{"data-id":"accountantsTable","headers":_vm.headers,"items":_vm.accountants,"loading":_vm.loading},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-card',[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.openDeleteAccountantDialog(item.id)}}},[_c('v-list-item-title',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.$t('general.delete')))])],1)],1)],1)],1)]}}],null,true)}),(_vm.deleteConfirmationDialog)?_c('confirmation-dialog',_vm._b({on:{"click-positive":_vm.deleteAccountant,"click-negative":function($event){_vm.deleteConfirmationDialog = false}},model:{value:(_vm.deleteConfirmationDialog),callback:function ($$v) {_vm.deleteConfirmationDialog=$$v},expression:"deleteConfirmationDialog"}},'confirmation-dialog',{
            title: _vm.$t('general.delete'),
            message: _vm.$t('general.confirm_delete_message'),
            loading: _vm.loading,
            negativeButton: _vm.$t('general.cancel'),
            positiveButton: _vm.$t('general.delete')
        },false)):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }