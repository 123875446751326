<template>
    <view-layout id="generalSettingsView">
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                </template>
            </page-header>
        </template>
        <template #content>
            <section v-if="currentUserData && !loading">
                <v-row>
                    <v-col cols="12">
                        <v-card outlined class="mb-4 pa-8">
                            <h3 class="my-4">
                                {{ $t('settings.change_password_section') }}
                            </h3>
                            <change-password-form dense />
                            <v-divider />
                        </v-card>
                    </v-col>
                </v-row>
            </section>
            <loader v-if="loading" />
        </template>
    </view-layout>
</template>

<script>
// --- Components ---
import ChangePasswordForm from '@/components/Forms/ChangePasswordForm.vue';

import Loader from '@/components/Loader.vue';
// --- State ---
import { mapState } from 'vuex';

// --- Error handling ---
import ViewLayout from '@/components/ViewLayout.vue';
import PageHeader from '@/components/PageHeader.vue';
import { billingMixin } from '@/mixins/billingMixin';

export default {
    components: {
        Loader,
        ChangePasswordForm,
        ViewLayout,
        PageHeader
    },

    mixins: [billingMixin],

    data() {
        return {
            loading: false,
            currentTab: 0,
            dialog: false
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData'])
    }
};
</script>
