<template>
    <view-layout id="generalSettingsView">
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                </template>
            </page-header>
        </template>
        <template #content>
            <section v-if="!loading">
                <v-row>
                    <v-col cols="12">
                        <v-card outlined class="mb-4 pa-8">
                            <update-accounting-office-basic-info-form
                                :accounting-office-data="accountingOfficeData"
                                @updated="fetchData"
                            />
                        </v-card>
                    </v-col>
                </v-row>
            </section>
            <loader v-else />
        </template>
    </view-layout>
</template>

<script>
import Loader from '@/components/Loader.vue';
// --- State ---
import { mapState } from 'vuex';

// --- Error handling ---
import ViewLayout from '@/components/ViewLayout.vue';
import PageHeader from '@/components/PageHeader.vue';
import { billingMixin } from '@/mixins/billingMixin';
import { getAccountingOfficeOfCurrentAccountantRequest } from '@/services/accountingoffice';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import UpdateAccountingOfficeBasicInfoForm from './components/UpdateAccountingOfficeBasicInfoForm.vue';

export default {
    components: {
        Loader,
        ViewLayout,
        PageHeader,
        UpdateAccountingOfficeBasicInfoForm
    },

    mixins: [billingMixin],

    data() {
        return {
            loading: false,
            currentTab: 0,
            dialog: false,
            accountingOfficeData: null
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData'])
    },

    async created() {
        await this.fetchData();
    },

    methods: {
        async fetchData() {
            try {
                this.loading = true;
                this.accountingOfficeData = await getAccountingOfficeOfCurrentAccountantRequest();
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>
