<template>
    <base-bottom-sheet v-bind="$attrs" class="add-accountingoffice-employee" v-on="$listeners">
        <template #header>
            <bottom-sheet-header>
                {{ $t('addAccountingOfficeEmployee.add_employee') }}
            </bottom-sheet-header>
        </template>

        <template #content>
            <v-form id="add-accountingoffice-employee-form" ref="form" v-model="valid" autocomplete="off">
                <v-row>
                    <v-col cols="12" md="6">
                        <text-field
                            v-model="employeeAccountant.firstName"
                            :label="$t('general.firstname')"
                            data-field="firstName"
                            outlined
                            dense
                            persistent-hint
                            required
                            :rules="nameRules"
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <text-field
                            v-model="employeeAccountant.name"
                            data-field="name"
                            :label="$t('general.lastname')"
                            outlined
                            dense
                            persistent-hint
                            required
                            :rules="nameRules"
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <text-field
                            ref="emailField"
                            v-model="employeeAccountant.email"
                            :label="$t('general.email')"
                            data-field="email"
                            outlined
                            dense
                            persistent-hint
                            required
                            :rules="emailRules"
                            type="email"
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <text-field
                            v-model="employeeAccountant.phone"
                            :label="$t('general.phone')"
                            data-field="phone"
                            outlined
                            dense
                            persistent-hint
                        />
                    </v-col>
                </v-row>
                <v-card-actions class="mt-5 pa-0">
                    <v-spacer />
                    <v-btn
                        color="primary"
                        data-action="create-accountantemployee"
                        class="font-weight-bold px-sm-8"
                        :loading="loading"
                        :disabled="!valid"
                        @click="handleSubmit"
                    >
                        {{ $t('addAccountingOfficeEmployee.register') }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </template>
    </base-bottom-sheet>
</template>

<script>
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import BottomSheetHeader from './BottomSheetHeader';

export default {
    components: {
        BottomSheetHeader
    },

    mixins: [formRulesMixin],
    inheritAttrs: false,

    props: {
        loading: {
            required: true,
            type: Boolean
        }
    },

    data() {
        return {
            valid: false,
            employeeAccountant: formDataFactory()
        };
    },

    methods: {
        resetForm() {
            this.employeeAccountant = formDataFactory();
        },
        handleSubmit() {
            this.$emit('submit', this.employeeAccountant);
        }
    }
};

function formDataFactory() {
    return {
        email: undefined,
        firstName: undefined,
        name: undefined,
        phone: undefined,
        password: undefined,
        language: undefined,
        address: {
            street: undefined,
            number: undefined,
            busNumber: undefined,
            city: undefined,
            zipcode: undefined
        }
    };
}
</script>
