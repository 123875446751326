<template>
    <div class="invitations-table">
        <v-data-table
            :headers="headers"
            :items="invitations"
            item-key="id"
            :items-per-page="10"
            :loading="loading"
            multi-sort
            sort-by="created"
            sort-desc
            :no-data-text="$t('general.no_invitations')"
        >
            <template #[`item.name`]="{ item }"> {{ item.user.firstName }} {{ item.user.name }} </template>
            <template #[`item.email`]="{ item }">
                {{ item.user.email }}
            </template>
            <template #[`item.actions`]="{ item }">
                <v-btn data-action="accept-invitation" icon color="green" @click="handleAcceptInvitation(item.id)">
                    <v-icon small> mdi-check </v-icon>
                </v-btn>

                <v-btn data-action="decline-invitation" icon color="red" @click="handleDeclineInvitation(item.id)">
                    <v-icon small> mdi-close </v-icon>
                </v-btn>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { getAllInvitationsRequest } from '@/services/invitations';
// --- State ---
import { mapActions } from 'vuex';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';

// --- Mixins ---
export default {
    data() {
        return {
            search: '',
            headers: [
                {
                    text: this.$t('general.name'),
                    align: 'start',
                    value: 'name'
                },
                {
                    text: this.$t('general.email'),
                    align: 'start',
                    value: 'email'
                },
                {
                    text: this.$t('general.actions'),
                    value: 'actions',
                    sortable: false
                }
            ],
            valid: true,
            loading: false,
            errorMessage: '',
            invitations: []
        };
    },

    created() {
        this.fetchInvitations();
    },

    methods: {
        ...mapActions('accountant', ['acceptInvitation', 'declineInvitation']),
        async handleAcceptInvitation(invitationId) {
            this.loading = true;
            try {
                await this.acceptInvitation(invitationId);
                // Request succeeded
                notify.call(this, {
                    title: this.$t('settings.invitation_accepted'),
                    text: this.$t('settings.invitation_accepted_info')
                });

                await this.fetchInvitations();
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        },
        async handleDeclineInvitation(invitationId) {
            this.loading = true;
            try {
                await this.declineInvitation(invitationId);

                // Request succeeded
                notify.call(this, {
                    title: this.$t('settings.invitation_declined'),
                    text: this.$t('settings.invitation_declined_info'),
                    type: 'success'
                });
                await this.fetchInvitations();
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        },
        fetchInvitations() {
            this.loading = true;
            getAllInvitationsRequest()
                .then((response) => {
                    this.invitations = response;
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>
