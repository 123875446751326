
import { formRulesMixin } from '@/mixins/formRulesMixin';
import { PropOptions } from 'vue';
import mixins from 'vue-typed-mixins';
import { mapState } from 'vuex';
import {
    ISchoolViewModel,
    ISocialsecretariatViewModel,
    AccountantRegisterUserData,
    IProfessionalactivityGetAllViewModel
} from 'dexxter-types';
import { UserTypesForRegistration } from '@/config/constants';
import { DeepNullable, MapStateToComputed } from '@/helpers/types';
import { InitializedUseraccountNotCompletedRegistrationAuthState } from '@/store/modules/auth';
import completeUserRegistrationWithCompanyNumberSubformsPart1 from '../../../../registration/completeUserRegistration/forms/completeUserRegistrationWithCompanyNumberSubforms/completeUserRegistrationWithCompanyNumberSubformsPart1.vue';
import CompleteUserRegistrationWithCompanyNumberSubformsPart2 from '../../../../registration/completeUserRegistration/forms/completeUserRegistrationWithCompanyNumberSubforms/completeUserRegistrationWithCompanyNumberSubformsPart2.vue';
import { ShowFeatureProps } from '@/views/registration/completeUserRegistration/forms/completeUserRegistrationWithCompanyNumberSubforms/completeUserRegistrationWithCompanyNumberSubformsPart2.types';

export default mixins(formRulesMixin).extend({
    components: {
        completeUserRegistrationWithCompanyNumberSubformsPart1,
        CompleteUserRegistrationWithCompanyNumberSubformsPart2
    },
    props: {
        value: {
            required: true
        } as PropOptions<DeepNullable<AccountantRegisterUserData>>,
        schools: {
            required: true
        } as PropOptions<ISchoolViewModel[]>,
        professionalactivities: {
            required: true
        } as PropOptions<IProfessionalactivityGetAllViewModel[]>,
        socialSecretariats: {
            required: true
        } as PropOptions<ISocialsecretariatViewModel[]>
    },

    mixins: [formRulesMixin],

    data() {
        return {
            registerableTiers: UserTypesForRegistration.map((_type) => ({
                type: _type,
                label: this.$t(`general.${_type}`)
            })),
            isFormValid: false,
            showFeatures: {
                showPromocode: {
                    activated: false
                },
                IBAN: {
                    customHint: this.$t('completeUserRegistration.IBAN_can_be_changed_by_user')
                }
            } as ShowFeatureProps
        };
    },

    computed: {
        ...(mapState('auth', [
            'currentUserData'
        ]) as MapStateToComputed<InitializedUseraccountNotCompletedRegistrationAuthState>),
        currentAccount: {
            get(): DeepNullable<AccountantRegisterUserData> {
                return this.value;
            },
            set(value: DeepNullable<AccountantRegisterUserData>) {
                this.$emit('input', value);
            }
        }
    },

    methods: {
        selectTier(selectedUserType: UserTypesForRegistration): void {
            this.currentAccount.completeUserRegistrationData.subscriptionType = 'pro_tier';
            this.currentAccount.completeUserRegistrationData.role = selectedUserType;
        },
        sortByNameAsc(a: ISocialsecretariatViewModel, b: ISocialsecretariatViewModel): number {
            return a.name >= b.name ? 1 : -1;
        },
        createClient() {
            this.$emit('submit', this.currentAccount);
        }
    }
});
