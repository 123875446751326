<template>
    <div>
        <v-form v-model="valid">
            <v-row v-if="currentAccountingOfficeData">
                <v-col cols="12" md="6">
                    <text-field
                        :value="currentAccountingOfficeData.company.email"
                        :label="$t('general.email')"
                        disabled
                        read-only
                    />
                </v-col>

                <v-col cols="12" md="6">
                    <text-field
                        v-model="currentAccountingOfficeData.company.name"
                        :label="$t('general.company_name')"
                        outlined
                        persistent-hint
                        required
                        dense
                        :rules="nameRules"
                        data-form="companyName"
                    />
                </v-col>

                <v-col cols="12" md="6">
                    <company-number-field
                        v-model="currentAccountingOfficeData.company.number"
                        selected-country="BE"
                        :label="$t('general.company_number')"
                        :rules="[...requiredRules, ...companyNumberRulesNotRequired('BE')]"
                        required
                        dense
                        data-form="companyNumber"
                    />
                </v-col>

                <v-col cols="12" md="6">
                    <text-field
                        v-model="currentAccountingOfficeData.company.ITAA"
                        :label="$t('forms.ITAA')"
                        outlined
                        persistent-hint
                        dense
                        data-form="ITAA"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <text-field
                        v-model="currentAccountingOfficeData.address.street"
                        :label="$t('forms.streetName')"
                        outlined
                        persistent-hint
                        required
                        dense
                        :rules="requiredRules"
                        data-form="street"
                    />
                </v-col>

                <v-col cols="12" md="3">
                    <text-field
                        v-model="currentAccountingOfficeData.address.number"
                        :label="$t('forms.houseNumber')"
                        outlined
                        persistent-hint
                        required
                        dense
                        :rules="requiredRules"
                        data-form="number"
                    />
                </v-col>

                <v-col cols="12" md="3">
                    <text-field
                        v-model="currentAccountingOfficeData.address.busNumber"
                        :label="$t('forms.busNumber')"
                        outlined
                        persistent-hint
                        dense
                        data-form="busNumber"
                    />
                </v-col>

                <v-col cols="12" md="6">
                    <text-field
                        v-model="currentAccountingOfficeData.address.zipcode"
                        v-mask="'####'"
                        :label="$t('forms.zipcode')"
                        outlined
                        persistent-hint
                        required
                        dense
                        :rules="requiredRules"
                        data-form="zipcode"
                    />
                </v-col>

                <v-col cols="12" md="6">
                    <text-field
                        v-model="currentAccountingOfficeData.address.city"
                        :label="$t('forms.city')"
                        outlined
                        persistent-hint
                        required
                        dense
                        :rules="requiredRules"
                        data-form="city"
                    />
                </v-col>

                <v-col cols="12">
                    <v-card-actions>
                        <v-spacer />
                        <submit-button
                            :disabled="!valid"
                            color="primary"
                            data-action="update-basic-user-info"
                            @click="updateAccountingOfficeData"
                        >
                            {{ $t('general.save') }}
                        </submit-button>
                    </v-card-actions>
                </v-col>
            </v-row>
        </v-form>
        <loader v-if="loading" />
    </div>
</template>

<script>
// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import Loader from '@/components/Loader';

import { ACCOUTNANT } from '@/config/constants';

import _ from 'lodash';
import CompanyNumberField from '@/components/FormComponents/CompanyNumberField.vue';
import { sendUpdateAccountingOfficeSettingsRequest } from '@/services/accountingoffice';

export default {
    components: { Loader, CompanyNumberField },

    mixins: [formRulesMixin],

    props: {
        accountingOfficeData: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            loading: false,
            valid: false,
            currentAccountingOfficeData: null,
            ACCOUNTANT: ACCOUTNANT
        };
    },

    watch: {
        accountingOfficeData: {
            immediate: true,
            handler() {
                this.currentAccountingOfficeData = _.cloneDeep(this.accountingOfficeData);
            }
        }
    },

    methods: {
        async updateAccountingOfficeData() {
            this.loading = true;
            try {
                await sendUpdateAccountingOfficeSettingsRequest(this.currentAccountingOfficeData);

                notify.call(this, {
                    title: this.$t('settings.user_settings_saved'),
                    text: this.$t('settings.user_settings_saved_full')
                });

                this.$emit('updated', this.currentAccountingOfficeData);
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>
