<template>
    <div class="users-table">
        <v-data-table
            data-id="accountantsTable"
            :headers="headers"
            :items="accountants"
            :loading="loading"
            class="pagination-table"
        >
            <template #[`item.actions`]="{ item }">
                <v-menu bottom left>
                    <template #activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-card>
                        <v-list dense>
                            <v-list-item @click.stop="openDeleteAccountantDialog(item.id)">
                                <v-list-item-title color="red">{{ $t('general.delete') }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>
            </template>
        </v-data-table>

        <confirmation-dialog
            v-if="deleteConfirmationDialog"
            v-model="deleteConfirmationDialog"
            v-bind="{
                title: $t('general.delete'),
                message: $t('general.confirm_delete_message'),
                loading: loading,
                negativeButton: $t('general.cancel'),
                positiveButton: $t('general.delete')
            }"
            @click-positive="deleteAccountant"
            @click-negative="deleteConfirmationDialog = false"
        />
    </div>
</template>

<script>
import { filteringMixin } from '../../mixins/filtering';

export default {
    components: {},
    mixins: [filteringMixin],

    props: {
        accountants: {
            required: true
        }
    },

    data() {
        const sortableColumns = [];
        return {
            accountantToBeDeletedId: null,
            deleteConfirmationDialog: false,
            headers: [
                {
                    text: this.$t('general.name'),
                    align: 'start',
                    value: 'name',
                    sortable: sortableColumns.includes('name')
                },
                {
                    text: this.$t('general.email'),
                    value: 'email',
                    sortable: sortableColumns.includes('email')
                },
                {
                    text: this.$t('general.role'),
                    value: 'role',
                    sortable: sortableColumns.includes('role')
                },
                {
                    text: this.$t('general.actions'),
                    value: 'actions',
                    sortable: false
                }
            ]
        };
    },

    methods: {
        deleteAccountant() {
            this.$emit('delete-accountant', this.accountantToBeDeletedId);
            this.deleteConfirmationDialog = false;
        },
        openDeleteAccountantDialog(accountantId) {
            this.accountantToBeDeletedId = accountantId;
            this.deleteConfirmationDialog = true;
        }
    }
};
</script>
