<template>
    <view-layout id="accountantDashboard">
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                </template>
                <template #menu>
                    <submit-button @click="createClient">{{ $t('accountants.create_user') }}</submit-button>
                </template>
            </page-header>
        </template>

        <template #content>
            <v-card outlined>
                <accountant-users-table />
            </v-card>
        </template>
    </view-layout>
</template>

<script>
// --- Components ---
import AccountantUsersTable from './components/AccountantUsersTable';
import ViewLayout from '@/components/ViewLayout.vue';
import PageHeader from '@/components/PageHeader';

export default {
    components: {
        AccountantUsersTable,
        ViewLayout,
        PageHeader
    },

    data() {
        return {
            currentTab: 0
        };
    },

    computed: {},

    methods: {
        createClient() {
            this.$router.push({
                name: 'create-client-for-accountingoffice'
            });
        }
    }
};
</script>
