export const billingMixin = {
    methods: {
        isInTrial(userBillingInfo) {
            if (!userBillingInfo) {
                return false;
            }
            return userBillingInfo.isInTrial;
        }
    }
};
