<template>
    <v-form v-model="valid" @keyup.native.enter="changePassword">
        <v-row>
            <v-col cols="12">
                <text-field
                    v-model="oldPassword"
                    :label="$t('changePassword.old_password')"
                    name="oldpassword"
                    prepend-inner-icon="mdi-lock"
                    outlined
                    type="password"
                    persistent-hint
                    required
                    :dense="dense"
                />
            </v-col>
            <v-col cols="12">
                <text-field
                    id="password"
                    v-model="newPassword"
                    :label="$t('changePassword.new_password')"
                    name="password"
                    type="password"
                    prepend-inner-icon="mdi-lock"
                    outlined
                    persistent-hint
                    required
                    :dense="dense"
                    :rules="passwordRules"
                />
            </v-col>
            <v-col cols="12">
                <text-field
                    id="passwordRepeat"
                    v-model="newPasswordRepeat"
                    :label="$t('changePassword.confirm_password')"
                    name="passwordRepeat"
                    type="password"
                    prepend-inner-icon="mdi-lock"
                    outlined
                    persistent-hint
                    required
                    :dense="dense"
                    :rules="passwordRules"
                />
            </v-col>
            <v-col cols="12">
                <v-card-actions>
                    <v-spacer />
                    <submit-button color="primary" :disabled="!validForm" @click="handleSubmit">
                        {{ $t('general.save') }}
                    </submit-button>
                </v-card-actions>
            </v-col>
        </v-row>
        <loader v-if="loading" />
    </v-form>
</template>

<script>
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import Loader from '../Loader';

export default {
    components: { Loader },
    mixins: [formRulesMixin],

    props: {
        dense: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            email: '',
            oldPassword: '',
            newPassword: '',
            newPasswordRepeat: '',
            valid: true,
            errorMessage: '',
            loading: false
        };
    },

    computed: {
        ...mapFields(['isLoggedin']),
        validForm() {
            return this.valid && this.passwordsMatch;
        },
        passwordsMatch() {
            return this.newPassword === this.newPasswordRepeat;
        }
    },

    methods: {
        ...mapActions('auth', ['changePassword']),
        async handleSubmit() {
            this.loading = true;
            try {
                await this.changePassword({
                    oldPassword: this.oldPassword,
                    newPassword: this.newPassword
                });
                notify.call(this, {
                    title: this.$t('settings.succesfuly_updated'),
                    text: this.$t('settings.succesfuly_updated'),
                    type: 'success'
                });
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>
